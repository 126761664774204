<template>
  <SignInLayout
    :subTitle="codeIsValid ? this.$t('resetPassword.subtitle', { brandName }) : ''"
    :title="this.$t('resetPassword.title')"
  >
    <div v-if="verificationLoaded" class="reset-password">
      <template v-if="!codeIsValid">
        <div>{{ codeInvalidMessage }}</div>
        <PrimaryButton :text="$t('resetPassword.gotoHome')" className="mt-4" size="x-large" @onClick="gotoHome" />
      </template>
      <form v-else novalidate v-on:submit.prevent="submit">
        <PasswordInput
          v-model="password"
          :errors="passwordErrors"
          :height="$vuetify.breakpoint.xsOnly ? 48 : 60"
          :hideDetails="false"
          :isPasswordHelper="true"
          :passwordRootChecks="passwordChecks"
          :placeholder="$t('general.inputs.password')"
          @blur="$v.password.$touch()"
          @change="onPasswordChange"
        />
        <PasswordInput
          v-model="repeatPassword"
          :errors="repeatPasswordErrors"
          :fullWidth="true"
          :height="$vuetify.breakpoint.xsOnly ? 48 : 60"
          :placeholder="$t('general.inputs.repeatPassword')"
          fieldName="repeatPassword"
          @blur="$v.repeatPassword.$touch()"
          @change="onInputChange"
        />
        <PrimaryButton :text="$t('resetPassword.submitBtn')" size="x-large" type="submit" />
      </form>
      <Snackbar v-model="snackbar" :text="error" :timeout="4000" type="error" @toggleSnackbar="toggleSnackbar" />
    </div>
  </SignInLayout>
</template>
<script>
import "firebase/auth";

import { required, sameAs } from "vuelidate/lib/validators";

import { AuthApi } from "@/api/auth";
import PasswordInput from "@/components/shared/PasswordInput";
import Snackbar from "@/components/shared/Snackbar";
import SignInLayout from "@/components/SignInLayout";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";

const passwordValid = (value, vm) => {
  return vm.passwordChecks.some((pc) => pc.value);
};
const passwordMinLength = (value) => {
  return value.length >= 8;
};

export default {
  name: "resetPasswordView",
  components: {
    SignInLayout,
    PasswordInput,
    PrimaryButton,
    Snackbar,
  },
  validations: {
    password: { required, passwordValid, passwordMinLength },
    repeatPassword: { required, sameAs: sameAs("password") },
  },
  data() {
    return {
      oobCode: "",
      password: "",
      repeatPassword: "",
      codeIsValid: true,
      codeInvalidMessage: "Something went wrong",
      brandName: process.env.VUE_APP_BRAND_NAME,
      snackbar: false,
      error: "",
      verificationLoaded: false,
    };
  },
  computed: {
    passwordChecks() {
      return [
        {
          value: this.password.length >= 8,
          text: this.$t("settings.changePassword.hint.length"),
          id: "characters",
        },
        {
          value: /(?=.*[a-z])(?=.*[A-Z])/.test(this.password),
          text: this.$t("settings.changePassword.hint.uppercase"),
          id: "letters",
        },
        {
          value: /(?=.*\d)/.test(this.password),
          text: this.$t("settings.changePassword.hint.number"),
          id: "digits",
        },
      ];
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.password") }));
      !this.$v.password.passwordMinLength &&
        errors.push(this.$t("validation.minLength", { field: this.$t("general.inputs.password"), length: 8 }));
      !this.$v.password.passwordValid && errors.push(this.$t("validation.passwordMustApply"));
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) return errors;
      !this.$v.repeatPassword.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.repeatPassword") }));
      !this.$v.repeatPassword.sameAs &&
        errors.push(
          this.$t("validation.sameAs", {
            field: this.$t("general.inputs.repeatPassword"),
            fieldAs: this.$t("general.inputs.password"),
          }),
        );
      return errors;
    },
  },
  methods: {
    toggleSnackbar() {
      this.snackbar = !this.snackbar;
    },
    onPasswordChange(password) {
      this.password = password;
    },
    onInputChange(value, fieldName) {
      this[fieldName] = value;
    },
    async gotoHome() {
      await this.$router.push({ path: `/` });
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      try {
        const data = await AuthApi.changePassword({ token: this.token, password: this.password });
        if (data?.status) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: "Success! Your password has been changed.",
            type: "success",
          });
          await this.$router.push({ path: `/auth/signin` });
        } else {
          snackBarEventBus.$emit(snackBarEventName, {
            message: "Something went wrong while saving the new password.",
            type: "error",
          });
        }
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Something went wrong while saving the new password.",
          type: "error",
        });
        this.snackbar = true;
        this.error = err.message;
      }
      this.loading = false;
    },
  },
  async mounted() {
    const url = new URL(window.location.href);
    this.token = url.searchParams.get("token");
    const data = await AuthApi.verifyPasswordResetCode(this.token);
    if (!data?.token) {
      this.codeIsValid = false;
    }
    this.verificationLoaded = true;
  },
};
</script>
<style lang="scss">
.reset-password {
  text-align: center;
  width: 440px;
  margin: 10px auto;

  @media (max-width: 767.98px) {
    width: auto;
    margin: 10px 20px;
  }
}
</style>
